import React, { Component } from "react";
import { MapProvider } from "./contexts/Map";
import HomePage from "./components/HomePage";

class App extends Component {
  render() {
    return (
      <MapProvider>
        <HomePage />
      </MapProvider>
    );
  }
}

export default App;
