import { getPassportControlExtraTime } from "utils/extraTime";
import { getSecurityCheckpointExtraTime } from "utils/extraTime";

const HUMAN_SPEED = 1.1; // meters per second

const getExtraTime = async extraTimeAreas => {
  if (!extraTimeAreas) {
    return 0;
  }

  const extraTimes = await Promise.all(
    extraTimeAreas.map(async area => {
      switch (area.name) {
        case "Security Checkpoints":
          return await getSecurityCheckpointExtraTime();
        case "Passport Control (Departures Gates E)":
        case "Passport Control (Transfer Gates E)":
        case "Passport Control (Arrivals Gates E)":
        case "Passport Control (Departures Gates D)":
        case "Passport Control (Arrivals Gates D)":
          return getPassportControlExtraTime(area.name);
        default:
          break;
      }
    })
  );

  return extraTimes.reduce((acc, time) => acc + time, 0);
};

const getRouteTimeString = async (routeLength, extraTimeAreas) => {
  const extraTimeMinutes = await getExtraTime(extraTimeAreas);
  const distance = Math.ceil(routeLength);
  const timeInSeconds = distance / HUMAN_SPEED + extraTimeMinutes * 60;
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  return `${distance}m / ${minutes ? `${minutes}m` : ""} ${
    seconds ? `${seconds}s` : ""
  }`;
};

export default getRouteTimeString;
