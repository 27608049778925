const RTPOINTS_KEY = "rtpoints";

const set = object => {
  localStorage.setItem(RTPOINTS_KEY, JSON.stringify(object));
};

const get = () => {
  const object = localStorage.getItem(RTPOINTS_KEY);
  return object ? JSON.parse(object) : null;
};

const getCategoryByPointId = (id, prefix = 'Baggage Trolley') => {
  const categories = get() || [];

  let category = categories.find(c => c.id === id);

  if (!category) {
    category = { id, name: `${prefix} ${categories.length + 1}` };
    categories.push(category);
    set(categories);
  }

  return category.name;
};

export default getCategoryByPointId;
