import client from "./client";
import convertSitumToPoints from "./convertSitumToPoint";

const EXAMPLE = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [1.3297686778822728, 103.77618891849757]
      },
      id: "84061156536025",
      properties: {
        floor_id: 9639,
        time: "2016-04-19T16:29:42.000Z",
        yaw: 4.36871,
        local_coordinates: [4.33366, 21.7118]
      }
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [1.3283752353044764, 103.77581097744394]
      },
      id: "84061156536026",
      properties: {
        floor_id: 9639,
        time: "2016-04-19T16:29:42.000Z",
        yaw: 4.36871,
        local_coordinates: [4.33366, 21.7118]
      }
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [1.2523871660232544, 103.81945037841797]
      },
      id: "566556133396",
      properties: {
        time: "2019-03-27T07:36:15.691Z",
        yaw: 6.201504026017984,
        local_coordinates: [104.08999633789062, 85.81999969482422],
        floor_id: 9931,
        building_id: 4907,
        level_height: 0
      }
    }
  ]
};

const getRealTimePoints = async () => {
  const { status, statusText, data } = await client.get(`/rtpoints`);

  if (status !== 200) {
    console.error("Getting markers error:", status, statusText);
    return null;
  }

  const raw = data.features.length ? data : EXAMPLE;
  const situmPoints = await convertSitumToPoints(raw);
  return situmPoints;
};

export default getRealTimePoints;
