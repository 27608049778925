/* global PCMap */
import * as React from "react";

const { Provider, Consumer } = React.createContext();

export class MapProvider extends React.Component<Props, State> {
  state = {
    selectedOccupant: null,
    isDirectionsOpen: false,
    isRouteOpen: false,
    openDirections: occupantId =>
      PCMap.showDirections({ destinationId: occupantId })
  };

  componentDidMount() {
    PCMap.on("SearchBox#OccupantSelected", occupant => {
      this.setState({ selectedOccupant: occupant });
    });
    PCMap.on("SearchBox#OccupantUnselected", () =>
      this.setState({ selectedOccupant: null })
    );
    PCMap.on("Directions#Open", () =>
      this.setState({ selectedOccupant: null, isDirectionsOpen: true })
    );
    PCMap.on("Directions#Close", () =>
      this.setState({ isDirectionsOpen: false })
    );
    PCMap.on("Route#Open", () =>
      this.setState({ selectedOccupant: null, isRouteOpen: true })
    );
    PCMap.on("Route#Close", () => this.setState({ isRouteOpen: false }));
    PCMap.on("Marker#OccupantSelected", occupant =>
      this.setState({ selectedOccupant: occupant })
    );
    PCMap.on("Map#Click", () => this.setState({ selectedOccupant: null }));
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export const withMapConsumer = (Component: any) =>
  class extends React.Component<any> {
    static displayName = "withMapConsumer";

    render() {
      return (
        <Consumer>{props => <Component {...this.props} {...props} />}</Consumer>
      );
    }
  };
