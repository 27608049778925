// @flow
import toilet2Image from "./images/IconSmallToilets2@3x.png";
import lift2Image from "./images/IconSmallLift2@3x.png";
import otherImage from "./images/IconOther@3x.png";
import baggageclaimImage from "./images/IconSmallBaggageclaim@3x.png";
import bankImage from "./images/IconSmallBank@3x.png";
import checkinImage from "./images/IconSmallCheckin@3x.png";
import escalatorImage from "./images/IconSmallEscalator2@3x.png";
import gateImage from "./images/IconSmallGate@3x.png";
import restaurantImage from "./images/IconSmallRestaurant@3x.png";
import securityImage from "./images/IconSecurity.png";
import shoppingImage from "./images/IconSmallShopping@3x.png";
import stairs2Image from "./images/IconSmallStairs2@3x.png";
import travelsImage from "./images/IconSmallTravels@3x.png";
import loungeImage from "./images/IconSmallLounge@3x.png";
import customsImage from "./images/IconCustoms.png";
import passportImage from "./images/IconPassport.png";
import zollImage from "./images/IconZoll.png";
// import carRentalImage from "./images/IconSmallCarRental@3x.png";
// import PRMImage from "./images/IconSmallWheelchair@3x.png";
// import ATMImage from "./images/IconSmallATM@3x.png";
// import trainImage from "./images/IconTrain.png";
// import infoImage from "./images/iconInfo@3x.png";
// import smokingImage from "./images/iconSmokingSmall@3x.png";
// import parkingImage from "./images/IconPaystation.png";
// import wifiImage from "./images/IconWifi.png";
// import dropoffImage from "./images/IconDropoff.png";
// import pickupImage from "./images/IconPickup.png";
// import familyImage from "./images/IconFamily.png";
// import taxrefundImage from "./images/IconTaxrefund.png";
// import obsdeckImage from "./images/IconObservationdeck.png";
// import taxiImage from "./images/IconSmallTaxi.png";
// import transferImage from "./images/IconTransfer.png";
// import lostImage from "./images/IconSmallLostandFound@3x.png";
// import baggageImage from "./images/IconSmallBaggage@3x.png";
// import bulkyImage from "./images/IconSmallBulky@3x.png";
// import kidsImage from "./images/IconSmallKids@3x.png";
// import showerImage from "./images/IconShower.png";
// import generalImage from "./images/IconGeneralServices.png";
// import coffeeImage from "./images/IconCoffee.png";
// import barImage from "./images/IconBar.png";
// import tramImage from "./images/IconTram.png";
// import busImage from "./images/IconBus.png";
// import coachImage from "./images/IconCoach.png";
// import hotelImage from "./images/IconHotel.png";
// import policeImage from "./images/IconPolice.png";
// import postImage from "./images/IconPost.png";
// import shuttleImage from "./images/IconShuttle.png";
// import ticketingImage from "./images/IconTicketing.png";
// import waitingImage from "./images/IconWaiting.png";
// import chapelImage from "./images/IconChapel.png";
// import infopointImage from "./images/IconInfoPoint.png";

//
// import coffeeImage from './images/IconSmallCoffee@3x.png';
// import entryImage from './images/IconEntry@3x.png';
// import mapKitImage from './images/IconMapKitObject@3x.png';
// import atmImage from './images/IconSmallATM@3x.png';
// import conferenceImage from './images/IconSmallConference@3x.png';
// import infoImage from './images/IconSmallInfo@3x.png';
// import lostAndFoundImage from './images/IconSmallLostandFound@3x.png';
// import stairs3Image from './images/IconSmallStairs3@3x.png';
// import toilet3Image from './images/IconSmallToilets@3x.png';
// import toiletFemaleImage from './images/IconSmallToiletsFemale@3x.png';
// import toiletMaleImage from './images/IconSmallToiletsMale@3x.png';
// import wheelChairImage from './images/IconSmallWheelchair@3x.png';
// import screenImage from "./images/IconMapKitObject@3x.png";

export default [
  {
    name: "Check-in",
    keys: ["check-in"],
    icon: checkinImage,
    color: "#0A0A82",
    iconMinZoom: 18,
    titleMinZoom: 18,
    dotMinZoom: 1000,
    showInCategoriesList: true,
    showInSearchResult: true,
    priority: 10
  },
  {
    name: "Gates",
    keys: ["gate area"],
    icon: gateImage,
    color: "#3c3c32",
    titleMinZoom: 18,
    dotMinZoom: 1000,
    showInCategoriesList: true,
    showInSearchResult: true,
    clusteringIdentifier: "gates",
    priority: 10
  },

  {
    name: "Baggage",
    keys: ["baggage carousel"],
    icon: baggageclaimImage,
    color: "#0A0A82",
    iconMinZoom: 19,
    titleMinZoom: 19,
    dotMinZoom: 19,
    showInCategoriesList: true,
    showInSearchResult: true,
    clusteringIdentifier: "baggage",
    priority: 10
  },
  {
    name: "Local Services",
    keys: ["local services"],
    color: "#3c3c32",
    icon: otherImage,
    iconMinZoom: 19,
    titleMinZoom: 19,
    dotMinZoom: 19,
    showInCategoriesList: false,
    showInSearchResult: true
  },
  {
    name: "Pier",
    keys: ["pier"],

    titleMinZoom: 14,
    titleMaxZoom: 15,

    showInCategoriesList: false,
    showInSearchResult: false,
    priority: 0
  },
  {
    name: "Food & Drink",
    keys: [
      "bar",
      "seafood bar",
      "cafes",
      "coffee & tea",
      "beer, wine & spirits",
      "restaurants",
      "take-away",
      "bakery"
    ],
    icon: restaurantImage,
    color: "#f3a342",
    iconMinZoom: 19,
    titleMinZoom: 19,
    dotMinZoom: 18,
    showInCategoriesList: true,
    showInSearchResult: true,
    clusteringIdentifier: "Food & Drink",
    priority: 10
  },
  {
    name: "Shopping",
    keys: [
      "men's clothing",
      "grocery",
      "electronics",
      "hobby shops",
      "books, mags, music & video",
      "home & garden",
      "auto parts & supplies",
      "jewelry",
      "lingerie",
      "automotive",
      "luggage",
      "appliances",
      "nurseries & gardening",
      "pet stores",
      "pharmacy",
      "photography stores & services",
      "cards & stationery",
      "sewing & alterations",
      "shoe store",
      "shoe stores",
      "shoe repair and key services",
      "shopping",
      "specialty food",
      "children's clothing",
      "sporting goods",
      "toy stores",
      "watches",
      "women's clothing",
      "accessories",
      "cosmetics & beauty supply",
      "department stores",
      "dry cleaning & laundry",
      "eyewear & opticians",
      "fashion",
      "art galleries",
      "florists",
      "furniture stores",
      "hobby shops-chanel",
      "hair salon"
    ],
    icon: shoppingImage,
    color: "#fac814",
    iconMinZoom: 19,
    titleMinZoom: 19,
    dotMinZoom: 18,
    showInCategoriesList: true,
    showInSearchResult: true
  },

  {
    name: "Lounges",
    keys: ["Lounge"],
    color: "#0A0A82",
    icon: loungeImage,
    iconMinZoom: 19,
    titleMinZoom: 19,
    dotMinZoom: 19,
    showInCategoriesList: false,
    showInSearchResult: true
  },

  {
    name: "Toilets",
    keys: ["restroom"],
    icon: toilet2Image,
    color: "#a0becc",
    titleMaxZoom: 0,
    iconMinZoom: 19,
    dotMinZoom: 1000,
    showInCategoriesList: false,
    showInSearchResult: true
  },
  {
    name: "Elevators",
    keys: ["elevator"],
    icon: lift2Image,
    color: "#a0becc",
    titleMaxZoom: 0,
    iconMinZoom: 20,
    dotMinZoom: 1000,
    showInCategoriesList: false,
    showInSearchResult: false
  },

  {
    name: "Escalators",
    keys: ["escalator"],
    icon: escalatorImage,
    color: "#a0becc",
    titleMaxZoom: 0,
    iconMinZoom: 20,
    dotMinZoom: 1000,
    showInCategoriesList: false,
    showInSearchResult: false
  },
  {
    name: "Stairs",
    keys: ["stairs"],
    icon: stairs2Image,
    color: "#a0becc",
    titleMaxZoom: 0,
    iconMinZoom: 20,
    dotMinZoom: 1000,
    showInCategoriesList: false,
    showInSearchResult: false
  },
  {
    name: "Banks",
    keys: ["banks & credit unions", "financial services", "ATM"],
    icon: bankImage,
    iconMinZoom: 1000,
    titleMinZoom: 1000,
    dotMinZoom: 1000,
    showInCategoriesList: false,
    showInSearchResult: true
  },

  {
    name: "Travel Services",
    keys: ["travel services"],
    icon: travelsImage,
    iconMinZoom: 1000,
    titleMinZoom: 1000,
    dotMinZoom: 1000,
    showInCategoriesList: false,
    showInSearchResult: true
  },
  {
    name: "Security Checkpoints",
    keys: ["security checkpoint"],
    icon: securityImage,
    color: "#ee3035",
    iconMinZoom: 17,
    titleMinZoom: 18,
    titleMaxZoom: 30,
    dotMinZoom: 1000,
    showInCategoriesList: false,
    showInSearchResult: false,
    priority: 10
  },
  {
    name: "Customs",
    keys: ["customs"],
    icon: customsImage,
    color: "#ee3035",
    iconMinZoom: 0,
    titleMinZoom: 18,
    titleMaxZoom: 30,
    dotMinZoom: 1000,
    showInCategoriesList: false,
    showInSearchResult: false,
    priority: 10
  },
  {
    name: "Customs - Zoll - Douane",
    keys: ["customs - zoll - douane"],
    icon: zollImage,
    color: "#0A0A82",
    iconMinZoom: 0,
    titleMinZoom: 18,
    titleMaxZoom: 30,
    dotMinZoom: 1000,
    showInCategoriesList: false,
    showInSearchResult: false,
    priority: 10
  },
  {
    name: "Passport Control",
    keys: ["passport control"],
    icon: passportImage,
    color: "#ee3035",
    iconMinZoom: 0,
    titleMinZoom: 18,
    titleMaxZoom: 30,
    dotMinZoom: 1000,
    showInCategoriesList: false,
    showInSearchResult: false,
    priority: 10
  }
];
