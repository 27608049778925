import getFloors from "./getFloors";

export default {
  _floors: null,

  get floors() {
    return (async () => {
      if (!this._floors) {
        this._floors = await getFloors();
      }

      return this._floors;
    })();
  }
};
