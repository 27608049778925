// @flow
export default {
  // Level outline (building shape)
  WayPath: {
    stroke: true,
    color: "#AA000A",
    weight: 2,
    opacity: 1.0,
    fill: false,
    clickable: false,
    interactive: false,
    keyboard: false
  },
  Indoor: {
    stroke: true,
    color: "#808080",
    weight: 1,
    opacity: 1.0,
    fill: true,
    fillColor: "#FAF5F0",
    fillOpacity: 1.0,
    clickable: false,
    priority: 99
  },
  Outdoor: {
    stroke: true,
    color: "#808080",
    weight: 1,
    opacity: 1.0,
    fill: true,
    fillColor: "#FAF5F0",
    fillOpacity: 1.0,
    clickable: false,
    priority: 99
  },
  Park: {
    stroke: true,
    color: "#808080",
    weight: 1,
    opacity: 1.0,
    fill: true,
    fillColor: "#C9E7B3",
    fillOpacity: 1.0,
    clickable: false
  },
  Number: {
    stroke: false,
    color: "#E6E6E6",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 1.0,
    clickable: false
  },
  Runway: {
    stroke: true,
    color: "#808080",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#808080",
    fillOpacity: 1.0,
    clickable: false
  },

  // Inner spaces of building
  Room: {
    stroke: true,
    color: "#FFFFFF",
    weight: 1,
    opacity: 1.0,
    fill: true,
    fillColor: "#E6E6E6",
    fillOpacity: 1.0,
    clickable: false
  },
  "Transit Platform": {
    stroke: true,
    color: "#FFFFFF",
    weight: 1,
    opacity: 1.0,
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 1.0,
    clickable: false
  },
  Walkway: {
    stroke: true,
    color: "#E6E6E6",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 1.0,
    clickable: false
  },
  Walkway_Airside: {
    stroke: true,
    color: "#E6E6E6",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#FFFADC",
    fillOpacity: 1.0,
    clickable: false
  },
  Passport_red: {
    stroke: false,
    color: "#E6E6E6",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#F38E8E",
    fillOpacity: 0.5
  },

  Passport_white: {
    stroke: false,
    color: "#E6E6E6",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 1.0,
    clickable: false
  },
  Elevator: {
    stroke: false,
    color: "#99CBFF",
    weight: 1,
    opacity: 0.2,
    fill: true,
    fillColor: "#B8D5DF",
    fillOpacity: 1.0,
    clickable: false
  },
  Escalator: {
    stroke: false,
    color: "#99CBFF",
    weight: 1,
    opacity: 0.2,
    fill: true,
    fillColor: "#B8D5DF",
    fillOpacity: 1.0,
    clickable: false
  },
  Stairs: {
    stroke: false,
    color: "#99CBFF",
    weight: 1,
    opacity: 0.2,
    fill: true,
    fillColor: "#B8D5DF",
    fillOpacity: 1.0,
    clickable: false
  },
  Opening: {
    stroke: true,
    color: "#FFFFFF",
    weight: 3,
    opacity: 1,
    fill: false,
    fillColor: "#B8D5DF",
    fillOpacity: 1.0,
    clickable: false
  },
  "Principal (Ped)": {
    stroke: true,
    color: "#FFFFFF",
    weight: 3,
    opacity: 1,
    fill: false,
    fillColor: "#B8D5DF",
    fillOpacity: 1.0,
    clickable: false
  },
  "Principal (Auto)": {
    stroke: true,
    color: "#FFFFFF",
    weight: 3,
    opacity: 1,
    fill: false,
    fillColor: "#B8D5DF",
    fillOpacity: 1.0,
    clickable: false
  },
  "Moving Walkway": {
    stroke: false,
    color: "#99CBFF",
    weight: 1,
    opacity: 0.2,
    fill: true,
    fillColor: "#B8D5DF",
    fillOpacity: 1.0,
    clickable: false
  },
  "Non-Public": {
    stroke: false,
    color: "#C3C8CD",
    weight: 2,
    fillColor: "#FAF5F0",
    fillOpacity: 1.0
  },
  "Open to Below": {
    stroke: false,
    color: "white",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#D0CACA",
    fillOpacity: 1.0,
    clickable: false
  },
  Ramp: {
    stroke: true,
    color: "#FFFFFF",
    weight: 1,
    opacity: 1.0,
    fill: true,
    fillColor: "#E6E6E6",
    fillOpacity: 1.0,
    clickable: false
  },
  Restroom: {
    stroke: false,
    color: "#FFFAFA",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#B8D5DF",
    fillOpacity: 1.0,
    clickable: false
  },
  "Restroom (Male)": {
    stroke: false,
    color: "#FFFAFA",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#B8D5DF",
    fillOpacity: 1.0,
    clickable: false
  },
  "Restroom (Female)": {
    stroke: false,
    color: "#FFFAFA",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#B8D5DF",
    fillOpacity: 1.0,
    clickable: false
  },
  Parking: {
    stroke: true,
    color: "#FFFFFF",
    weight: 1,
    opacity: 1.0,
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 1.0,
    clickable: false
  },
  // Fixtures
  "Baggage Carousel": {
    stroke: false,
    color: "#C4C4C4",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#E6E6E6",
    fillOpacity: 1.0,
    clickable: false
  },
  "Column": {
    stroke: false,
    color: "#f7d48d",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#f7d48d",
    fillOpacity: 1.0,
    clickable: false
  },
  "Furniture": {
    stroke: false,
    color: "#f7d48d",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#f7d48d",
    fillOpacity: 1.0,
    clickable: false
  },
  
  Wall: {
    stroke: true,
    color: "#B8D5DF",
    weight: 1,
    opacity: 1.0,
    fill: true,
    fillColor: "#B8D5DF",
    fillOpacity: 1.0,
    clickable: false,
    zoom: [19, 66]
  },
  Vegetation: {
    stroke: true,
    color: "#D2E8F3",
    weight: 1,
    opacity: 1.0,
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 1.0,
    clickable: false
  },
  "Check-in Desk": {
    stroke: true,
    color: "#D2E8F3",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 1.0,
    clickable: false
  },
  Kiosk: {
    stroke: true,
    color: "#D2E8F3",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 1.0,
    clickable: false
  },
  "Boarding Gate Desk": {
    stroke: true,
    color: "#D2E8F3",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 1.0,
    clickable: false
  },
  Obstruction: {
    stroke: true,
    color: "#D2E8F3",
    weight: 2,
    opacity: 1.0,
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 1.0,
    clickable: false
  },
  Area: {
    stroke: false,
    color: "#D2E8F3",
    weight: 1,
    opacity: 1.0,
    fill: false,
    fillColor: "#FFFFFF",
    fillOpacity: 1.0,
    clickable: false
  },
  "Area-Security": {
    stroke: false,
    color: "#D2E8F3",
    weight: 1,
    opacity: 1.0,
    fill: true,
    fillColor: "#F38E8E",
    fillOpacity: 0.5,
    clickable: false
  },
  Line: {
    stroke: true,
    color: "#808080",
    weight: 1,
    opacity: 1.0,
    fill: false,
    fillColor: "#FAF5F0",
    fillOpacity: 1.0,
    clickable: false,
    priority: 5
  },
  Building: {
    stroke: true,
    color: "#808080",
    weight: 1,
    opacity: 1.0,
    fill: false,
    fillColor: "#fafaf2",
    fillOpacity: 1.0,
    clickable: false,
    priority: 10
  }
};
