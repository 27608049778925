import axios from "axios";

// Take latest value from the string like
// '3-10' - return 10
// '5+' - return 5
// '9' - return 9
const takeLatestValue = str => {
  const matches = str.match(/\d+(?=\+?$)/);

  if (!matches) {
    return 0;
  }

  return Number.parseInt(matches[0]);
};

const getSecurityCheckpointExtraTime = async () => {
  try {
    const { status, data } = await axios.get(
      "https://m-esb.flughafen-zuerich.ch/restv2/airport_apis_siko/waitingtimes",
      {
        auth: {
          username: "pointConsultingAirportApiUser",
          password: "As2r#i$k89Us&A5fGt82Po2e"
        }
      }
    );
    return status === 200 ? takeLatestValue(data.maxWaitingTime) : 0;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export default getSecurityCheckpointExtraTime;
