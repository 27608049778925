const passportControlExtraTimes = {
  "Passport Control (Departures Gates E)": {
    "5:00": ["closed", 4],
    "8:00": [4, 10],
    "9:30": [10, 7],
    "11:30": [7, 10],
    "13:00": [10, 4],
    "16:15": [4, 7],
    "19:00": [7, 4],
    "20:00": [4, 7],
    "21:45": [7, 4],
    "23:15": [4, "closed"]
  },
  "Passport Control (Transfer Gates E)": {
    "5:00": ["closed", 4],
    "6:15": [4, 7],
    "8:00": [7, 4],
    "11:00": [4, 7],
    "12:15": [7, 4],
    "23:15": [4, "closed"]
  },
  "Passport Control (Arrivals Gates E)": {
    "5:00": ["closed", 7],
    "6:45": [7, 10],
    "9:30": [10, 7],
    "11:15": [7, 10],
    "15:15": [10, 7],
    "20:15": [7, 10],
    "22:30": [10, 4],
    "23:15": [4, "closed"]
  },
  "Passport Control (Departures Gates D)": {
    "5:00": ["closed", 4],
    "5:45": [4, 7],
    "7:00": [7, 4],
    "8:15": [4, 7],
    "9:45": [7, 4],
    "11:00": [4, 7],
    "13:00": [7, 4],
    "14:30": [4, 7],
    "16:15": [7, 4],
    "23:15": [4, "closed"]
  },
  "Passport Control (Arrivals Gates D)": {
    "5:00": ["closed", 4],
    "10:45": [4, 7],
    "12:00": [7, 4],
    "23:15": [4, "closed"]
  }
};

const convertTimeToSeconds = time => {
  const [hours, minutes] = time.split(":").map(t => Number.parseInt(t));
  return (hours * 60 + minutes) * 60;
};

export const getPassportControlExtraTime = (name, now) => {
  const passportControl = passportControlExtraTimes[name];

  if (!passportControl) {
    throw new Error(`${name} was not found`);
  }

  const nowInSeconds = convertTimeToSeconds(now);

  for (let [time, extraTime] of Object.entries(passportControl)) {
    const timeInSeconds = convertTimeToSeconds(time);

    if (nowInSeconds === timeInSeconds) {
      return extraTime[1];
    }

    if (nowInSeconds < timeInSeconds) {
      if (extraTime[0] === "closed" && extraTime[1] !== "closed") {
        return Math.floor((timeInSeconds - nowInSeconds) / 60) + extraTime[1];
      }

      return extraTime[0];
    }
  }

  // In case when in the table of times we can't find the required time front
  // we suppose that the current time is between last time front in table and
  // the midnight, so we find the remain time to the midnight and add it to
  // the time when the area is open (first entry in table)
  const midnightInSeconds = convertTimeToSeconds("23:59") + 1 * 60;
  const timeToMidnight = Math.floor((midnightInSeconds - nowInSeconds) / 60);
  const firstTimeFront = Object.entries(passportControl)[0];
  const timeToOpen = Math.floor(convertTimeToSeconds(firstTimeFront[0]) / 60);
  const firstExtraTime = firstTimeFront[1][1];
  return timeToMidnight + timeToOpen + firstExtraTime;
};

export default name => {
  const now = new Date();
  return getPassportControlExtraTime(
    name,
    `${now.getHours()}:${now.getMinutes()}`
  );
};
