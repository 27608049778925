import client from "./client";

const EXAMPLE = [
  {
    building_id: 4907,
    id: 9639,
    project_id: 4951,
    level: 2,
    level_height: 0.0,
    created_at: "2019-03-04T06:19:18.607+01:00",
    updated_at: "2019-03-06T08:56:33.419+01:00",
    maps: {
      scale: 2.7767046710788374,
      map_url:
        "https://dashboard.situm.es/uploads/floor/9639/680aa3fb-ec10-41a7-a1f6-acd24ebbcf0c.png",
      map_id: "680aa3fb-ec10-41a7-a1f6-acd24ebbcf0c.png"
    }
  }
];

const getFloors = async () => {
  const { status, statusText, data } = await client.get(`/floors`);

  if (status !== 200) {
    console.error("Getting markers error:", status, statusText);
    return null;
  }

  const floors = data.length ? data : EXAMPLE;

  return floors;
};

export default getFloors;
