import axios from "axios";

const getPOIInfo = async id => {
  try {
    const { status, data } = await axios.get(
      `https://www.flughafen-zuerich.ch/webapi/poidatastele/${id}?languageCode=en`
    );
    return status === 200 ? data : null;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export default getPOIInfo;
