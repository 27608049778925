/* global PCMap */
/* eslint-disable import/no-webpack-loader-syntax */
import React from "react";
import styled from "styled-components";
import categories from "data/categories";
import styles from "data/styles";
import getRouteTimeString from "./getRouteTimeString";
import buildings from "!json-loader!data/geo/suss/Buildings.geojson";
import levels from "!json-loader!data/geo/suss/Levels.geojson";
import fixtures from "!json-loader!data/geo/suss/Fixtures.geojson";
import sections from "!json-loader!data/geo/suss/Sections.geojson";
import units from "!json-loader!data/geo/suss/Units.geojson";
import occupants from "!json-loader!data/geo/suss/Occupants.geojson";
import points from "!json-loader!data/geo/suss/Points.geojson";
import openings from "!json-loader!data/geo/suss/Openings.geojson";
import routes from "data/geo/suss/Routes.json";
import { getRealTimePoints } from "thirdParty/situm";

const MapContainer = styled.div``;

let lastLatitude = 0;
let lastLongitude = 0;
let lastFloorLevel = 0;

class Map extends React.Component {
  componentDidMount() {
    (async () => {
      const rtPoints = await getRealTimePoints();
      console.log(rtPoints);
    })();

    PCMap.init(this.map, {
      appleMapKitJWToken:
        "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjlQTU1ISExUNVQifQ.eyJpc3MiOiIzTkVIWEVIUTI3IiwiaWF0IjoxNTYxNjIyMjczLCJleHAiOjE1Nzc4MTg4MDB9.JRLS_MbFjJrd8lJgYmn8jkc4uxCFUn68h8LQUkUNr1NoRzgXHUpnoE_J5dH0RwPSb7-_RZpC7Fp2V_XdAEUOUA",
      showPopups: false,
      geoData: {
        buildings,
        levels,
        fixtures,
        sections,
        units,
        routes,
        occupants,
        points,
        openings
      },
      categories,
      styles,
      ordinal: "0",
      center: [1.329507, 103.776247],
      minZoom: 10,
      maxZoom: 3000000000,
      zoom: 1000,
      rotation: 0,
      routeArrowsGap: 5,
      // Commented out to allow autosizing the arrows
      // routeArrowsOffset: 7,
      // routeArrowsSize: "1.5em",
      routeArrowsAutoSizeMultiplier: 4,
      routeArrowsAutoOffsetMultiplier: -0.45,
      routeArrowsIcon: "",
      routeArrowsColor: "#59aaf8",
      routeArrowsExtraTimeColor: "#ff0000",
      routeColor: "#00000000",
      routeExtraTimeColor: "#00000000",
      ordinalsOptions: [
        {
          id: "4"
        },
        {
          id: "3"
        },
        {
          id: "2"
        },
        {
          id: "1"
        },
        {
          id: "0"
        },
        {
          id: "-1"
        },
        {
          id: "-2"
        }
      ],
      extraTimeOptions: [
        {
          name: "Security Checkpoints",
          categories: ["Security Checkpoint"],
          routes: [
            {
              start: "8b33a3d6-86b8-4f02-a851-fd5835405355",
              end: "8b33a3d6-86b8-4f02-a851-fd5835405357"
            },
            {
              start: "8e7262b5-ab45-40d7-b64e-83cb3c25071c1",
              end: "8e7262b5-ab45-40d7-b64e-83cb3c25071c2"
            },
            {
              start: "605cffe4-e30f-4451-b5c0-0765d9603031",
              end: "605cffe4-e30f-4451-b5c0-0765d9603033"
            },
            {
              start: "743ac805-b955-4e6d-acb4-68738e6a4628",
              end: "743ac805-b955-4e6d-acb4-68738e6a4630"
            },
            {
              start: "83929008-833c-4a58-ab7a-057930f6c9b1",
              end: "83929008-833c-4a58-ab7a-057930f6c9b3"
            },
            {
              start: "0f246dd7-3e19-4fc1-9f82-1efb8eb0bc6f1",
              end: "0f246dd7-3e19-4fc1-9f82-1efb8eb0bc6f2"
            },
            {
              start: "0e8f4375-6873-44f3-977b-072675ffb434",
              end: "0e8f4375-6873-44f3-977b-072675ffb436"
            }
          ]
        },
        {
          name: "Passport Control (Departures Gates E)",
          includeIds: ["f40b0264-6172-454f-9d01-b1019b097f96"],
          routes: [
            {
              start: "f40b0264-6172-454f-9d01-b1019b097f961",
              end: "f40b0264-6172-454f-9d01-b1019b097f962"
            }
          ]
        },
        {
          name: "Passport Control (Transfer Gates E)",
          includeIds: ["f40b0264-6172-454f-9d01-b1019b097f94"],
          routes: [
            {
              start: "f40b0264-6172-454f-9d01-b1019b097f941",
              end: "f40b0264-6172-454f-9d01-b1019b097f942"
            }
          ]
        },
        {
          name: "Passport Control (Arrivals Gates E)",
          includeIds: ["f40b0264-6172-454f-9d01-b1019b097f97"],
          routes: [
            {
              start: "f40b0264-6172-454f-9d01-b1019b097f971",
              end: "f40b0264-6172-454f-9d01-b1019b097f972"
            }
          ]
        },
        {
          name: "Passport Control (Departures Gates D)",
          includeIds: ["be6fd904-93fc-4a04-a9a8-e708a8c759a9"],
          routes: [
            {
              start: "be6fd904-93fc-4a04-a9a8-e708a8c759a91",
              end: "be6fd904-93fc-4a04-a9a8-e708a8c759a92"
            }
          ]
        },
        {
          name: "Passport Control (Arrivals Gates D)",
          includeIds: ["be6fd904-93fc-4a04-a9a8-e708a8c759a4"],
          routes: [
            {
              start: "be6fd904-93fc-4a04-a9a8-e708a8c759a41",
              end: "be6fd904-93fc-4a04-a9a8-e708a8c759a42"
            }
          ]
        }
      ],
      getRouteTimeString,
      getRoutePaddings() {
        if (window.matchMedia("(min-width: 600px)").matches) {
          // tablets and desktops
          return {
            paddingTop: 200,
            paddingLeft: 100,
            paddingRight: 100,
            paddingBottom: 200
          };
        } else {
          // mobile devices except tablets
          return {
            paddingTop: 50,
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 10
          };
        }
      },
      getCurrentPosition() {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            position => {
              const {
                coords: { latitude, longitude, floorLevel }
              } = position;

              // Store last valid coordinate
              if (Number.isFinite(latitude) && Number.isFinite(longitude)) {
                lastLatitude = latitude;
                lastLongitude = longitude;
              }

              // Store last valid floorLevel
              if (Number.isFinite(floorLevel)) {
                lastFloorLevel = floorLevel;
              }

              // DEBUG: geolocation
              document.getElementById("debug").innerText = `
              lastUpdate: ${new Date(position.timestamp).toLocaleTimeString()}
              floorLevel: ${floorLevel}
              latitude: ${latitude}
              longitude: ${longitude}
              `;

              resolve({
                latitude: Number.isFinite(latitude) ? latitude : lastLatitude,
                longitude: Number.isFinite(longitude)
                  ? longitude
                  : lastLongitude,
                ordinal: Number.isFinite(floorLevel)
                  ? floorLevel
                  : lastFloorLevel
              });
            },
            error => {
              reject(error);
            },
            { enableHighAccuracy: true, timeout: 1500 }
          );
        });
      },
      currentPositionUpdateIntervalMs: 2000
    });

    // Remember selected occupant
    let selectedOccupantId = null;
    PCMap.on("SearchBox#OccupantSelected", occupant => {
      selectedOccupantId = occupant.id;
    });

    // Set selected occupant when directions open
    PCMap.on("Directions#Open", () => {
      PCMap.setDirections({ destinationId: selectedOccupantId });
      selectedOccupantId = null;
    });

    // Clear directions dialog when it's closed
    PCMap.on("Directions#Close", () => {
      PCMap.clearDirections();
    });
  }

  render() {
    return (
      <MapContainer
        ref={ref => {
          this.map = ref;
        }}
      />
    );
  }
}

export default Map;
