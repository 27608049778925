import React from "react";
import styled from "styled-components";
import Map from "./Map";
import Popup from "./Popup";

const Container = styled.div`
  height: 100vh;

  /*@media (min-width: 600px) {
    position: relative;
    height: calc(100vh - 100px);
    margin: 50px;
    overflow: hidden;
  }*/
`;

class HomePage extends React.Component {
  render() {
    return (
      <Container>
        <Map />
        <Popup />
      </Container>
    );
  }
}

export default HomePage;
