import React from "react";
import Media from "react-media";
import styled from "styled-components";
import { withMapConsumer } from "contexts/Map";
import { getPOIInfo } from "utils/poiInfo";
import clockImage from "./images/clock.png";
import phoneImage from "./images/phone.png";
import webImage from "./images/web.png";
import menuImage from "./images/menu.png";
import { ReactComponent as ArrowUpImage } from "./images/arrow-up.svg";
import { ReactComponent as ArrowDownImage } from "./images/arrow-down.svg";

const ASSETS_BASE_URL = "https://www.flughafen-zuerich.ch";

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 65%;
  padding: 0 15px 15px 15px;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  z-index: 410;
  transform: translateY(
    ${({ expanded, collapsedHeight }) =>
      expanded ? "0" : `calc(100% - ${collapsedHeight}px)`}
  );
  transition: transform 300ms;
  overflow-y: ${({ expanded }) => (expanded ? "scroll" : "hidden")};

  &:before {
    content: "";
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 15px;
    margin-bottom: 20px;
    background: white;
    box-shadow: 0px 0px 15px 15px white;
  }

  @media (min-width: 600px) {
    top: 0;
    width: 350px;
    max-height: 100%;
    padding: ${({ isDirectionsOpen, isRouteOpen }) =>
        isRouteOpen ? "165px" : isDirectionsOpen ? "255px" : "90px"}
      15px 15px;
    padding-top: 0;
    transform: none;
    transition: none;
    overflow-y: scroll;

    &:before {
      content: "";
      display: block;
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: ${({ isDirectionsOpen, isRouteOpen }) =>
        isRouteOpen ? "190px" : isDirectionsOpen ? "235px" : "70px"};
      margin-bottom: 20px;
      background: white;
      box-shadow: 0px 0px 15px 15px white;
    }
  }
`;

const Hide = styled.div`
  opacity: ${({ hide }) => (hide ? 0 : 1)};
`;

const Header = styled.header`
  display: flex;

  > div {
    width: 70%;
    overflow: hidden;
  }
`;

const Title = styled.h2`
  margin-top: 0;
  font-size: 1.3em;
  line-height: 1.3;
  ${({ expanded }) =>
    !expanded &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `};
`;

const Category = styled.div`
  color: gray;
  font-size: 0.9em;
`;

const Description = styled.div`
  margin: 1em 0.2em;
`;

const ImagesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px;
  margin: 1em 0 0.5em;
  list-style: none;

  & > li {
    width: 50%;
    min-height: 90px;
  }

  & > li:nth-child(3n + 1) {
    width: 100%;
    min-height: 180px;
  }
`;

const ImagesItem = styled.li``;

const Image = styled.img`
  width: 100%;
  padding: 1px;
`;

const DirectionsButton = styled.button`
  font-size: 1em;
  width: 100%;
  height: 34px;
  margin: 1em 0 0.3em 0;
  border-radius: 5px;
  color: white;
  background-color: #3477f6;
`;

const AdditionalInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 0;
  border-bottom: 1px solid #eee;
  font-size: 0.9em;
`;

const Icon = styled.img`
  flex-shrink: 0;
  width: 1.8em;
  height: 1.8em;
  margin-right: 1.5em;
`;

const CategoryIcon = styled(Icon)`
  margin: 0 1em 0 0;
`;

const Link = styled.a`
  text-decoration: none;
  color: #0078a8;
  overflow: hidden;

  :hover {
    color: #0078a8;
  }
`;

const ArrowButton = `
  height: 20px;
  width: 20px;
  padding: 0 10px 10px;
  box-sizing: initial;

  @media (min-width: 600px) {
    display: none;
  }
`;

const ArrowUpButton = styled(ArrowUpImage)`
  ${ArrowButton};
`;

const ArrowDownButton = styled(ArrowDownImage)`
  ${ArrowButton};
`;

const Popup = ({
  selectedOccupant,
  openDirections,
  isDirectionsOpen,
  isRouteOpen
}) => {
  const [info, setInfo] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [collapsedHeight, setCollapsedHeight] = React.useState(0);
  const headerRef = React.useRef(null);

  React.useEffect(() => {
    if (selectedOccupant) {
      getPOIInfo(selectedOccupant.id).then(info => {
        setInfo({
          description: info ? info.DescriptionShort : null,
          images: info ? info.Images : null,
          file: info ? info.File : null
        });
      });
    }
  }, [selectedOccupant]);

  React.useEffect(() => {
    if (headerRef.current) {
      const paddings = 16 * 2;
      const slightHideEffectHeight = 35;
      const directionsButtonHeight = 28;
      setCollapsedHeight(
        headerRef.current.clientHeight +
          paddings +
          slightHideEffectHeight +
          directionsButtonHeight
      );
    }
  }, [selectedOccupant]);

  // Don't render non existen occupant
  if (!selectedOccupant) {
    return null;
  }

  const handleDirectionsButtonClick = () => {
    if (selectedOccupant) {
      openDirections(selectedOccupant.id);
    }
  };

  const {
    name,
    category,
    categoryIcon,
    level: {
      name: level,
      building: { name: building }
    },
    zone,
    area,
    phone,
    hours,
    website
  } = selectedOccupant;

  // Show `building - level`
  let location = [];
  building && location.push(building);
  level && location.push(level);

  // Show `zone - area - level`
  // if there is zone or area defined
  if (zone || area) {
    location = [];
    zone && location.push(zone);
    area && location.push(area);
    level && location.push(level);
  }

  const { images, description, file } = info;

  return (
    <Media query="(min-width: 600px)">
      {isMinWidth600 => (
        <Container
          expanded={expanded}
          collapsedHeight={collapsedHeight}
          isDirectionsOpen={isDirectionsOpen}
          isRouteOpen={isRouteOpen}
        >
          <Header ref={headerRef}>
            <CategoryIcon src={categoryIcon} />
            <div>
              <Title expanded={expanded || isMinWidth600}>{name}</Title>
              <Category>
                <div>{category}</div>
                <div>{location.join(" - ")}</div>
              </Category>
            </div>
            {expanded ? (
              <ArrowDownButton onClick={() => setExpanded(false)} />
            ) : (
              <ArrowUpButton onClick={() => setExpanded(true)} />
            )}
          </Header>
          {!expanded && !isMinWidth600 && (
            <DirectionsButton onClick={handleDirectionsButtonClick}>
              Directions
            </DirectionsButton>
          )}
          <Hide hide={!expanded && !isMinWidth600}>
            {images && (
              <ImagesList>
                {images.map(image => (
                  <ImagesItem key={image}>
                    <Image src={`${ASSETS_BASE_URL}${image}`} alt={name} />
                  </ImagesItem>
                ))}
              </ImagesList>
            )}
            <DirectionsButton onClick={handleDirectionsButtonClick}>
              Directions
            </DirectionsButton>
            {description && <Description>{description}</Description>}
            {hours && (
              <AdditionalInfo>
                <Icon src={clockImage} />
                {hours}
              </AdditionalInfo>
            )}
            {phone && (
              <AdditionalInfo>
                <Icon src={phoneImage} />
                <Link href={`tel:${phone}`}>{phone}</Link>
              </AdditionalInfo>
            )}
            {website && (
              <AdditionalInfo>
                <Icon src={webImage} />
                <Link href={website}>{website}</Link>
              </AdditionalInfo>
            )}
            {file && (
              <AdditionalInfo>
                <Icon src={menuImage} />
                <Link href={`${ASSETS_BASE_URL}${file}`}>Menu</Link>
              </AdditionalInfo>
            )}
          </Hide>
        </Container>
      )}
    </Media>
  );
};

export default withMapConsumer(Popup);
