/* eslint-disable import/no-webpack-loader-syntax */
import produce from "immer";
import isPointInPolygon from "point-in-polygon";
import levels from "!json-loader!data/geo/suss/Levels.geojson";
import building from "./building";
import getCategoryByPointId from "./getCategoryByPointId";

const convert = async raw => {
  const floors = await building.floors;

  const ordinalsPolygons = levels.features.reduce((acc, level) => {
    const ordinalPolygons = acc[level.properties.ORDINAL] || [];
    if (level.geometry.type === "Polygon") {
      ordinalPolygons.push({
        levelId: level.properties.LEVEL_ID,
        levelPolygon: level.geometry.coordinates[0]
      });
    }

    // In case level has many polygons we add all of them to the ordinal
    // with the same level id
    if (level.geometry.type === "MultiPolygon") {
      level.geometry.coordinates.forEach(polygon => {
        ordinalPolygons.push({
          levelId: level.properties.LEVEL_ID,
          levelPolygon: polygon[0]
        });
      });
    }

    acc[level.properties.ORDINAL] = ordinalPolygons;
    return acc;
  }, {});

  return produce(raw, draft => {
    draft.features.forEach(feature => {
      // Get ordinal id by the floor_id
      const floorId = feature.properties.floor_id;
      const floor = floors.find(f => f.id === floorId);

      if (!floor) {
        console.error(`Floor for the point ${feature.id} wasn't found`);
        return;
      }

      // Look for the corresponding level of the point,
      const ordinal = floor.level;
      const coordinates = feature.geometry.coordinates;
      const point = [coordinates[1], coordinates[0]];
      const ordinalPolygons = ordinalsPolygons[ordinal];

      if (!ordinalPolygons) {
        console.error(`Ordinal for the point ${feature.id} wasn't found`);
        return;
      }

      const levelPolygon =
        ordinalPolygons &&
        ordinalPolygons.find(p => isPointInPolygon(point, p.levelPolygon));

      if (!levelPolygon) {
        console.error(`Level for the point ${feature.id} wasn't found`);
        return;
      }

      feature.geometry.coordinates = point;
      feature.properties.CATEGORY = getCategoryByPointId(feature.id);
      feature.properties.NAME = getCategoryByPointId(feature.id);
      feature.properties.POINT_ID = feature.id;
      feature.properties.LEVEL_ID = levelPolygon ? levelPolygon.levelId : null;
    });
  });
};

export default convert;
